
import { defineComponent } from "vue";
import { Form, Field } from "vee-validate";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
//import Swal from "sweetalert2/dist/sweetalert2.min.js";
// import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "new-entity-course",
  components: {
    Form,
    Field,
    Datatable,
  },
  data() {
    return {
      batch: {},
      employee: {},
      details: {},
      weekplan: {},
      entityTypes: [],
      industry: [],
      entityInfos: [],
      divisions: [],
      districts: [],
      subDistricts: [],
      empDistricts: [],
    };
  },
  // async created() {
  //   await this.getEntityTypes();
  //   await this.getEntityInfos();
  // },
  methods: {
    async formSubmit() {
      //form submit
    },
    // async getEntityInfos() {
    //   await ApiService.get("entity/infos")
    //     .then((response) => {
    //       this.entityInfos = response.data;
    //     })
    //     .catch(({ response }) => {
    //       console.log(response);
    //     });
    // },
    // async getEntityTypes() {
    //   await ApiService.get("entity/types")
    //     .then((response) => {
    //       this.entityTypes = response.data;
    //     })
    //     .catch(({ response }) => {
    //       console.log(response);
    //     });
    // },
    // courseInfo() {
    //   this.courseInfoData = true;
    // },
    // courseNotice() {
    //   this.showCourseNotice = true;
    // },
    // trainingProviderNotice() {
    //   this.showtrainingProviderNotice = true;
    // },
  },
  setup() {
    const AssociationSchema = [];
    return {
      AssociationSchema,
    };
  },
});
